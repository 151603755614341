<template>
  <div>
    <div class="custom-mount"></div>
  </div>
</template>

<script  type="text/babel">
import WebOfficeSDK from '../utils/web-office-sdk-solution-v1.1.24.es.js';
export default {
  data () {
    return {
      type: '',//文档格式
    }
  },
  created () {
    console.log(WebOfficeSDK, 'wps-sdk')
    let file_id = this.$route.query.file_id
    let extension = this.$route.query.type
    console.log(this.type, 'type')
    window.onload = async function () {
      if (extension == 'doc' || extension == 'docx' || extension == 'wps' || extension == 'txt') {
        this.type = 'w'
      } else if (extension == 'xls' || extension == 'xlsx') {
        this.type = 's'
      } else if (extension == 'ppt' || extension == 'pptx') {
        this.type = 'p'
      } else if (extension == 'pdf') {
        this.type = 'f'
      }
      const instance = WebOfficeSDK.init({
        // 必填项
        officeType: this.type,
        appId: 'SX20230506FGXPTX',
        fileId: file_id,
        token: localStorage.getItem('token'),
        // 可选项
        // cooperUserAttribute: {
        //   isCooperUsersAvatarVisible: true, // 是否显示协作用户头像
        //   isShowTopArea: false, // 隐藏顶部区域（头部和工具栏）
        //   isShowHeader: false, // 隐藏头部区域
        //   isBrowserViewFullscreen: false, // 是否在浏览器区域全屏
        //   isIframeViewFullscreen: false, // 是否在 iframe 区域内全屏
        //   acceptVisualViewportResizeEvent: true // 控制 WebOffice 是否接受外部的 VisualViewport
        //   // cooperUsersColor: [
        //   //   {
        //   //     userId: 'xxx', // 用户 id
        //   //     color: '#F65B90' // 用户光标颜色
        //   //   }
        //   // ]
        // }
      })

      instance.ApiEvent.AddApiEventListener('fileOpen', data => {
        console.log('fileOpen: ', data)
      })

      // 需要等待实例 ready 之后再调用 API
      await instance.ready()

      // 自动识别
      const app = instance.Application


      instance.ApiEvent.AddApiEventListener('DocumentSaveStatus', data => {
        console.log('DocumentSaveStatus: ', data)
      })

      // 操作窗口缩放比例
      app.ActiveWindow.Zoom = 100


    }
  },
  methods: {

  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.custom-mount {
  width: 100%;
  height: 100%;
}
</style>